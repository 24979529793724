/** @jsxImportSource @emotion/react */

import React, { useState } from "react"
import {Card, Container, Paper } from "@mui/material";
import { css } from '@emotion/react'

const ContactPage = () => {
    const [showAlexaEmail, setShowAlexaEmail] = useState(false);
    const [showJenEmail, setShowJenEmail] = useState(false);

    return (
        <Container>
            <Paper>
                <h2>Our Team</h2>
                <div
                css={
                    css`
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                    `
                }
                >
                    <Card variant="outlined"
                        css={css`
                        flex: 1;
                        margin: 5px
                        `}

                    >
                        <h3>Alexa Little</h3>
                        <p>Owner & CEO</p>
                        <div
                        css={css`
                            padding-bottom: 5px;
                        `}
                        >
                            { showAlexaEmail ? <a href="mailto:alexa@cutiecollectables.com">alexa@cutiecollectables.com</a> :
                            <button
                                type="button"
                                onClick={() => {
                                    setShowAlexaEmail(true)
                                }}
                                >
                                Reveal Email
                                </button>
                            }
                        </div>
                    </Card>
                    <Card variant="outlined" css={
                        css`
                            flex: 1;
                            margin: 5px;
                            `
                        }>
                        <h3>Jen Hughes</h3>
                        <p>Co-owner & Head of Sales</p>
                        <div
                            css={css`
                            padding-bottom: 5px;
                        `}
                        >
                        {showJenEmail ? <a href="mailto:alexa@cutiecollectables.com">jen@cutiecollectables.com</a> :

                            <button
                            type="button"
                            onClick={() => {
                                setShowJenEmail(true)
                            }}
                            >
                            Reveal Email
                            </button>
                        }
                        </div>
                    </Card>
                </div>
            </Paper>
        </Container>
    )
}

export default ContactPage;
