import { Outlet } from 'react-router-dom';
import './App.css';
import NavigationHeader from './components/NavigationHeader';

function App() {
  return (
    <div className="App">
      <div className="container">
        <header>
          <NavigationHeader />
        </header>
        <main>
          <Outlet />
        </main>
        <footer>
          <p>Copyright &copy; 2023 Cutie Collectables LLC</p>
        </footer>
      </div>
    </div>
  );
}

export default App;
