/** @jsxImportSource @emotion/react */

import React from "react"
import {Card, Container } from "@mui/material"
import { Link } from "react-router-dom"
import { css } from '@emotion/react'

const Root = () => {
    return (
        <Container>
            <h1>Welcome to Cutie Collectables!</h1>
            <Card
            css={css`margin: 5px 0`}
            >
                <p>Looking to buy cards?  Check out our ebay and TCGPlayer stores on our <Link to={"/products"}>Products Page</Link></p>
            </Card>
            <Card
            css={css`margin: 5px 0`}
            >
                <p>Have something to sell?  Shoot either of us an email via our <Link to={"/contact"}>Contact Us</Link> page,
                and we'd be happy to take a look.</p>
            </Card>
        </Container>
    )
}

export default Root
