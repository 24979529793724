import React from "react"
import {Card, Container } from "@mui/material";
const ProductsPage = () => {
    return (
        <Container>
            <h2>We sell cards via TCGPlayer and eBay at this time</h2>
            <Card>
                <h4>Click below to be redirected to our TCGPlayer store</h4>
                <a href="https://www.tcgplayer.com/search/all/product?seller=a731c6ee">
                    <img src={"/images/TCGplayer-logo.png"} alt={"TCGPlayer logo linkout"}/>
                </a>
            </Card>
            <Card>
                <h4>Click below to be redirected to our eBay store</h4>
                <a href="https://www.ebay.com/usr/cutie-collectables">Click here</a>
            </Card>
        </Container>
    )
}

export default ProductsPage;
