import { isRouteErrorResponse, useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);
  const errorText = isRouteErrorResponse(error) ? <i>{error.status} {error.statusText} {error.data.message}</i> : <i>Unknown error</i>

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        {errorText}
      </p>
    </div>
  );
}
