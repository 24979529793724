import React from "react"
import {Card, Container } from "@mui/material";

const AboutPage = () => {
    return (
        <Container>
            <h2>About Us</h2>
            <Card>
                <p>We're a women founded, owned and operated small business, based in Chicago IL,
                    founded in 2022. Both owners are passionate about all collectables,
                     especially trading cards & Pokémon.
                </p>
                <p>As of now we're an online only store, but we're looking to expand into
                    convention sales, and establishing a more local presence in our community.
                </p>
            </Card>

        </Container>
    )
}

export default AboutPage;
